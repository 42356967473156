import collections from '../../../config/config/.collections.json'
import { algoliaAppId, algoliaSearchKey, algoliaIndex, port, domain } from '../../../config/config/settings'
import axios from "axios";
import { ref } from 'vue'
import algoliasearch from 'algoliasearch/lite'

let controllers = {
    authors: ['get'],
    // scripture: { validate: ['post'] }
}

// console.log('collections', collections)

const client = algoliasearch(algoliaAppId, algoliaSearchKey);
const algolia = client.initIndex(algoliaIndex);

let api = `https://${domain}`
let ws = `wss://${domain}/graphql`

if (process.env.NODE_ENV != 'production') {
    api = `http://localhost:${port}`
    ws = `ws://localhost:${port}/graphql`
}

const Mango = collections.reduce((a, c) => {

    let runQuery = ({ limit, page, search, fields, id, sort, depthLimit } = {}) => {

        let fullQuery


        const params = { limit, page, search, fields, sort, depthLimit }

        if (params.search != undefined) params.search = JSON.stringify(params.search)
        if (params.fields != undefined) params.fields = JSON.stringify(params.fields)
        if (params.sort != undefined) params.sort = JSON.stringify(params.sort)

        const query = Object.keys(params)
            .filter(key => params[key] != undefined)
            ?.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            ?.join('&') || ''
        // console.log(query)

        fullQuery = `${api}/${c.name}/${id || ''}?${query}`

        let Authorization = window.localStorage.getItem('token')

        return new Promise((resolve, reject) => {
            axios.get(fullQuery, { headers: { Authorization } })
                .then(response => resolve(response?.data?.response))
                .catch(e => reject(e))
        })

    }

    let runGraphql = (query) => {

        let Authorization = window.localStorage.getItem('token')
        query = { query }
        return new Promise((resolve, reject) => {
            axios.post(`${api}/graphql`, query, { headers: { Authorization } })
                .then(response => resolve(response?.data?.data))
                .catch(e => reject(e))
        })

    }

    let runAlgolia = (search, query, algoliaFilters) => {

        search = search || ''

        let filters = `collection:${c.name}`
        if (algoliaFilters) filters += ` AND ${algoliaFilters}`

        let algoliaQuery = {
            page: query?.page || 0,
            filters,
            hitsPerPage: query?.limit || 10
        }

        if (query?.fields) algoliaQuery.attributesToRetrieve = query.fields

        return new Promise((resolve, reject) => {
            algolia.search(search, algoliaQuery)
                .then(({ hits, nbHits }) => {
                    hits.forEach(h => h.id = h.objectID)
                    resolve({ hits, nbHits })
                })
        })

    }

    let save = (data) => {
        let { id } = data
        let method = id ? 'put' : 'post'

        // // Remove _id and computed fields
        delete data.collection
        delete data._id
        delete data.id

        for (let field of c.fields) {
            if (field.computed) delete data[field.name]
            if (field.relationship) data[field.name] = Array.isArray(data[field.name]) ? data[field.name].map(r => r.id) : data[field.name]?.id ? data[field.name].id : data[field.name]
        }
        for (let name in data) {
            if (name.includes('__')) delete data[name]
        }

        let payload = { ...data }

        let Authorization = window.localStorage.getItem('token')

        return new Promise((resolve, reject) => {
            axios[method](`${api}/${c.name}/${id || ''}`, payload, { headers: { Authorization } })
                .then(response => resolve(response?.data?.response))
                .catch(e => reject(e))
        })
    }

    let deleteEntry = (data) => {
        let id = data.id || data

        let Authorization = window.localStorage.getItem('token')

        return new Promise((resolve, reject) => {
            axios.delete(`${api}/${c.name}/${id || ''}`, { headers: { Authorization } })
                .then(response => resolve(response?.data))
                .catch(e => reject(e))
        })
    }

    a[c.name] = runQuery
    a[c.name]['save'] = save
    a[c.name]['delete'] = deleteEntry
    a[c.singular] = (id, query) => runQuery({ id, ...query })

    a[c.name]['search'] = runAlgolia
    a[c.name]['search']['init'] = (search, query, algoliaFilters) => {
        let loading = ref(true)
        let data = ref(null)
        let error = ref(null)
        let totalResults = ref(null)

        let response = runAlgolia(search, query, algoliaFilters)
            .then(response => {
                data.value = response.hits
                totalResults.value = response.nbHits
                loading.value = false
            })
            .catch(e => {
                loading.value = false
                error.value = e
            })

        return { data, loading, error }
    }

    a[c.name]['init'] = ({ limit, page, search, fields, id, sort } = {}) => {

        let loading = ref(true)
        let data = ref(null)
        let error = ref(null)

        let response = runQuery({ limit, page, search, fields, id, sort })
            .then(response => {
                data.value = response
                loading.value = false
            })
            .catch(e => {
                loading.value = false
                error.value = e
            })

        return { data, loading, error }

    }

    a[c.singular]['init'] = (id) => a[c.name]['init']({ id })

    a.relationRequest = ({ limit, page, search, fields, id, sort, depthLimit, path } = {}) => {

        let fullQuery


        const params = { limit, page, search, fields, sort, depthLimit }

        if (params.search != undefined) params.search = JSON.stringify(params.search)
        if (params.fields != undefined) params.fields = JSON.stringify(params.fields)
        if (params.sort != undefined) params.sort = JSON.stringify(params.sort)

        const query = Object.keys(params)
            .filter(key => params[key] != undefined)
            ?.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            ?.join('&') || ''
        // console.log(query)

        fullQuery = `${api}/${path}?${query}`

        let Authorization = window.localStorage.getItem('token')

        return new Promise((resolve, reject) => {
            axios.get(fullQuery, { headers: { Authorization } })
                .then(response => resolve(response?.data?.response))
                .catch(e => reject(e))
        })

    }
    a.relationRequest.init = (query) => {

        let loading = ref(true)
        let data = ref(null)
        let error = ref(null)

        let response = a.relationRequest(query)
            .then(response => {
                data.value = response
                loading.value = false
            })
            .catch(e => {
                loading.value = false
                error.value = e
            })

        return { data, loading, error }

    }


    a.graphql = runGraphql
    a.graphql.init = (query) => {

        let loading = ref(true)
        let data = ref(null)
        let error = ref(null)

        let response = runGraphql(query)
            .then(response => {
                data.value = response
                loading.value = false
            })
            .catch(e => {
                loading.value = false
                error.value = e
            })

        return { data, loading, error }

    }

    return a

}, {})

Mango.search = (search, query, algoliaFilters) => {

    search = search || ''

    let filters = ``
    if (algoliaFilters) filters += `${algoliaFilters}`

    let algoliaQuery = {
        page: query?.page || 0,
        filters,
        hitsPerPage: query?.limit || 10
    }

    if (query?.fields) algoliaQuery.attributesToRetrieve = query.fields

    return new Promise((resolve, reject) => {
        algolia.search(search, algoliaQuery)
            .then(({ hits }) => {
                hits.forEach(h => h.id = h.objectID)
                resolve(hits)
            })
    })

}

Mango.login = ({ email, password }) => {
    return new Promise((resolve, reject) => {
        axios.post(`${api}/controllers/account/login`, { email, password })
            .then(response => {
                window.localStorage.setItem('token', response.data.token)
                window.localStorage.setItem('user', response.data.memberId)
                window.localStorage.setItem('email', email)
                resolve(response.data)
            })
            .catch(e => reject(e))
    })
}

Mango.controllers = Object.keys(controllers).reduce((a, c) => {

    a[c] = {}

    for (let method of controllers[c]) {
        a[c][method] = () => {

            return new Promise((resolve, reject) => {
                console.log('method', method)
                console.log('`${api}/controllers/${c}`', `${api}/controllers/${c}`)
                axios[method](`${api}/controllers/${c}`)
                    .then(response => resolve(response?.data))
                    .catch(e => reject(e))
            })

        }
    }

    for (let method of controllers[c]) {
        a[c][method]['init'] = () => {

            let loading = ref(true)
            let data = ref(null)
            let error = ref(null)

            let response = a[c][method]().then(r => {
                data.value = r
                loading.value = false
            })

            return { data, loading, error }
        }
    }

    return a

}, {})

Mango.collections = collections
Mango.ws = ws

Mango.online = async () => { try { return (await axios.get(`${api}/controllers/test`))?.data?.includes('🥭') } catch (e) { return false } }

export default Mango
