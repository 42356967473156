<template>

    <div class="w-full h-screen flex flex-col items-center justify-center">

        <div class="space-x-4 p-4 w-full">

            <div class="grid grid-cols-2 w-full max-w-2xl mx-auto gap-4">

                <div class="w-full flex flex-col items-center gap-4" v-for="side in sides" :key="side.label">
                    <button
                        class="w-full aspect-square border-4 rounded-full max-w-64 flex items-center justify-center text-3xl uppercase"
                        :class="side.selected ? 'border-blue-500' : 'opacity-50'"
                        @click="selectSide(side)"
                        v-html="side.label"
                    />
                    <div class="gap-2 text-center">
                        <div>{{ dayjs(side.time).format('h:mma') }}</div>
                        <div
                            v-if="side.selected && (now - side.time > 1000*60*1)"
                            :class="{
                                'text-red-500': now - side.time > 1000*60*60*2,
                                'text-yellow-500': now - side.time > 1000*60*60*1,
                                'text-green-500': now - side.time < 1000*60*60*1
                            }"
                        >
                            {{ dayjs(side.time).from(now) }}
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <button v-if="backup" class="underline mt-4 md:mt-12" @click="undo" v-html="'Undo'" />

        <Login class="self-end max-w-sm mx-auto" @loggedIn="syncFeeding" />

    </div>

</template>

<script>
import Mango from '../../helpers/mango'
import Login from '../layout/login.vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
    components: { Login },
    inject: ['store','darkMode'],
    data() {
        return {
            now: Date.now(),
            interval: null,
            backup: null,
            sides:
                window.localStorage.getItem('sides') ? JSON.parse(window.localStorage.getItem('sides')) :
                ['l','r'].map(side => ({
                    label: side.toUpperCase(),
                    time: window.localStorage.getItem(side) || 0,
                    selected: false
                }))
            ,
        }
    },
    mounted() {
        window.localStorage.removeItem('l')
        window.localStorage.removeItem('r')

        this.syncFeeding();
        document.addEventListener("visibilitychange", this.handleVisibilityChange);

        // Enable dark mode after 8pm
        let now = new Date(this.now)
        if (now.getHours() > 20 || now.getHours() < 6) this.darkMode.enabled = true
        else this.darkMode.enabled = false
    },
    watch: {
        sides: {
            handler(sides) {
                window.localStorage.setItem('sides', JSON.stringify(sides))
            },
            deep: true
        }
    },
    methods: {
        dayjs,
        handleVisibilityChange() {
            if (document.visibilityState === 'visible') {
                let now = new Date(this.now)
                console.log('now.', now)
                console.log('now.getHours()', now.getHours())
                this.syncFeeding();
                // Enable dark mode after 8pm
                if (now.getHours() > 20 || now.getHours() < 6) this.darkMode.enabled = true
                else this.darkMode.enabled = false
            }
        },
        async syncFeeding() {
            if (!this.store.user?.id) return
            let response = await Mango.feedings({ search: {author: this.store.user.id}, sort: {created: -1}, limit: 5, fields: ['id','side','created','author'], depthLimit: 1 })
            console.log('response', response)
            if (!response.length) return
            if (new Date(response?.[0]?.created) < new Date(this.sides?.find(s => s.selected)?.time)) return
            for (let side of this.sides) side.selected = false
            let left = response.find(s => s.side == 'l') || { side: 'l', created: 0 }
            let right = response.find(s => s.side == 'r') || { side: 'r', created: 0 }
            let sides = [left, right].map(s => ({
                label: s.side,
                time: new Date(s.created),
                selected: s.id == response[0].id,
            }))
            this.sides = sides
        },
        selectSide(s) {
            Mango.feedings.save({ side: s.label.toLowerCase() })
            this.backup = JSON.parse(JSON.stringify(this.sides))
            for (let s of this.sides) s.selected = false
            s.selected = true
            s.time = Date.now()
            setTimeout(() => {
                this.backup = null
            }, 1000*15);
        },
        undo() {
            this.sides = this.backup
            this.backup = null
        }
    },
    async created() {
        this.interval = setInterval(async () => {
            this.now = Date.now()
        }, 500);
    },
    beforeDestroy() {
        clearInterval(this.interval)
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }
}
</script>
