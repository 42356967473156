export default (phone) => {
    if (!phone) return ''
    let formattedPhoneNumber = ''
    let number = phone.replace(/\D/g, '').split('')
    if (number.length == 11) {
        formattedPhoneNumber += '+' + number[0] + ' '
        number.splice(0, 1)
    }
    for (let [index, digit] in number) {
        if (number.length >= 3) {
            if (index == 0) formattedPhoneNumber += '('
            if (index == 6) formattedPhoneNumber += '-'
            if (number[index]) formattedPhoneNumber += number[index]
            if (index == 2) formattedPhoneNumber += ') '
        }
    }
    return formattedPhoneNumber
}
