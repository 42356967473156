<template>
    <div :class="{'dark':darkMode.enabled}">
        <div class="relative from-white to-slate-50 bg-gradient-to-b dark:from-black dark:to-gray-700 dark:selection:bg-gray-400 dark:text-gray-400 w-full min-h-screen">

            <RouterView v-slot="{ Component, route }" @navHidden="navHidden = $event">
                <Suspense :timeout="200" >
                    <Component :is="Component" :key="route.meta.usePathKey ? route.path : undefined" />
                    <template #fallback><spinner /></template>
                </Suspense>
            </RouterView>

        </div>
    </div>
</template>

<script>
import login from './components/layout/login.vue'
export default {
  components: { login },
    inject: ['store','darkMode'],
    data() {
        return {
            navHidden: false
        }
    }
}
</script>
