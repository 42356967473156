import axios from 'axios'
import { computed, createApp, defineAsyncComponent, reactive, ref } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { MotionPlugin } from '@vueuse/motion'
import { port, domain } from '../../config/config/settings'
import VueClipboard from 'vue3-clipboard'
import Vue3TouchEvents from "vue3-touch-events";
import App from './App.vue'
import './index.css'

/*
    If these are dynamicly imported I end up with a blank
    screen while the route is waiting for the component
    to download... not sure how to fix.
*/

import Home from './components/pages/home.vue'
const isDev = ['true', '1'].includes(import.meta.env.VITE_DEVMODE)

const routes = [

    { path: '/', component: Home },
    { path: '/protectedRoute', component: Home, meta: { protected: true, fallback: '/' } },

]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {

        let toPath = to.matched?.[0]?.path

        let sameComponent = to.matched?.[0]?.components?.default == from.matched?.[0]?.components?.default
        let sameComponentExceptions = ['/inventory/:id', /account\/*/g]
        let exceptionExists = sameComponentExceptions.some(e => !!toPath?.match(e)?.length)

        if (to.hash) { return { el: to.hash, behavior: 'smooth' } }
        if (savedPosition) return savedPosition
        if (sameComponent && !exceptionExists) return null

        return { top: 0 }

    },
    routes,
})


const app = createApp(App)

app.use(router)
app.use(Vue3TouchEvents)
app.use(MotionPlugin)
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})

/*

Global Properties

*/

import breakpoints from './helpers/breakpoints'
import darkMode from './helpers/darkMode'
import formatPhone from './helpers/formatPhone'
import { initUser } from './helpers/user';

app.provide('breakpoints', breakpoints().breakpoints)
app.provide('darkMode', darkMode().darkMode)

const user = initUser()

let api = `http://localhost:${port}`
// let api = `https://${domain}`
if (!isDev) api = `https://${domain}`

const store = reactive({

    user,
    api,

})

let Authorization = window.localStorage.getItem('email') + ';' + window.localStorage.getItem('user')
axios.defaults.headers.common['Authorization'] = Authorization

app.provide('store', store)
app.provide('axios', axios)

app.provide('mode', import.meta.env.MODE)
app.provide('formatPhone', formatPhone)


/*

Global Components

*/

import Mango from './helpers/Mango.vue'
import Spinner from './components/layout/spinner.vue'
import Modal from './components/layout/modal.vue'
import Button from './components/partials/button.vue'

app.component('Mango', Mango)
app.component('Spinner', Spinner)
app.component('Modal', Modal)
app.component('btn', Button)
// app.component('resource', defineAsyncComponent(Layouter.vue')))

/*

Route Guard

*/

router.beforeEach((to, from, next) => {

    // Log in google analytics...
    if (window.ga) window.ga('send', 'pageview', to.fullPath);

    if (to.meta?.protected && !store.user?.id) {
        console.log('protected', store.user?.id)
        store.login = { next: to.path }
        if (to.meta?.fallback) {
            console.log('to', to)
            // Get params from path and add them to the fallback path (/event/:id)
            let fallback = to.meta.fallback?.split('/')?.map(p => {
                if (p.includes(':')) {
                    let param = p.replaceAll(':', '')
                    return to.params[param] || ''
                } else {
                    return p
                }
            })?.join('/')
            return next(fallback || to.meta.fallback)
        }
        return next('')
    }
    next()
})

app.mount('#app')
