<template>
    <div class="w-full p-8 flex items-center">
        <slot
            :logout="logout"
            :login="login"
            :createAccount="createAccount"
            :sendRecoveryEmail="sendRecoveryEmail"
            :resetPassword="resetPassword"
        >
            <form @submit.stop.prevent class="rounded-lg flex flex-col space-y-2 w-full">

                <template v-if="!store?.user?.id">
                    <div class="text-sm text-gray-500 text-center">Enter your email to sync across devices.</div>
                    <input type="email" v-model.trim="user.email" placeholder="Email" @change="login" />
                </template>

                <div v-else class="text-xs space-y-1 flex flex-col items-center">
                    <div class="text-center">{{ store?.user?.email }}</div>
                    <button class="underline" @click="logout">Logout</button>
                </div>

            </form>
        </slot>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { validateEmail } from '../../helpers/email'
import { getUser } from '../../helpers/user'
import Mango from '../../helpers/mango'

// Function for setting cookies
let setCookie = function (cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default {
    inject: ['store','axios'],
    data() {
        return {
            user: {
                email: null,
            },
            processing: false,
            creatingAccount: true,
            guest: true,
            forgotPassword: false,
        }
    },
    watch: {
        loggedIn() {
            this.$emit('hide')
        }
    },
    methods: {
        validateEmail,
        async login() {
            this.processing = true
            let member = (await Mango.login({ email: this.user.email }))

            if (member?.memberId) {
                if (member.roles?.includes('admin')) member.admin = true

                window.localStorage.setItem('user', member.memberId)
                window.localStorage.setItem('token', member.token)
                window.localStorage.setItem('email', this.user.email)

                setCookie(`Authorization`, `${member.token}`)
                this.axios.defaults.headers.common['Authorization'] = `${member.token}`

                const user = await getUser()
                this.store.user = user
                if (user?.company?.id) this.store.theme = user.company

                this.$emit('loggedIn')

                let restrictedPath = this.store?.login?.next || '/'
                if (restrictedPath) this.$router.push(restrictedPath)

                this.$emit('hide')
            } else if (member?.invalidFields) {
                if (member.invalidFields.includes('email')) return this.createAccount()
                else Swal.fire({ title: `Invalid ${member.invalidFields.join(', ')}`, icon: 'error' })
            }

            this.processing = false
        },
        logout() {
            this.store.user = {}
            window.user = null
            window.localStorage.removeItem('user')
            setCookie(`Authorization`, ``)
            delete this.axios.defaults.headers.common['Authorization']
            this.$emit('loggedOut')
            this.$emit('hide')
        },
		async createAccount() {
			if (!this.validateEmail(this.user.email)) return Swal.fire('Email must be a valid email.')
			this.processing = true

			var data = {
				...this.user
			}

			let response = await Mango.members.save(data)
            if (!response.id) {
                Swal.fire('Account Exists', response, 'info')
            } else {
                await this.login()
            }
            this.$emit('accountCreated')
            this.$emit('hide')
            this.processing = false
        },
		async sendRecoveryEmail () {
			this.processing = true
			let response = await this.axios.post(`${this.store.api}/controllers/account/sendResetInstructions`, {email: this.user.email, forgot: true})
            if (response.data.success) Swal.fire('Success!', `Recovery instructions sent to: ${this.user.email}`, 'success')
            else Swal.fire('Invalid Email!', `The following user does not exist: ${this.user.email}`, 'warning')
            this.$emit('emailSent')
            this.$emit('hide')
            this.processing = false
		},
		async resetPassword () {
            if (this.user.password.length < 6) return Swal.fire('Password must be at least 6 characters.')
			this.processing = true

            let data = {
                email: this.$route.query.email,
                salt: this.$route.query.salt,
                password: this.user.password,
            }

			let response = await this.axios.post(`${this.store.api}/controllers/account/resetPassword`, data)
            if (response.data.success) {
                this.user.email = this.$route.query.email
                await this.login()
                this.$router.push({query: null})
                swal('Success!', 'Your password has been reset.', 'success')
            } else {
                swal('Invalid Link!', `The link you're using is invalid.`, 'error')
            }

            this.processing = false
            this.$emit('passwordReset')
            this.$emit('hide')
		},
    },
    computed: {
        guestPassword() { return Math.random().toString(36).substr(2)+Math.random().toString(36).substr(2) },
        labelText() { return this.resettingPassword ? 'Reset Password' : this.forgotPassword ? 'Recover Password' : this.creatingAccount ? this.guest ? 'Continue as Guest' : 'Create Account' : 'Login' },
        buttonText() { return this.resettingPassword ? 'Update Password' : this.forgotPassword ? 'Send Recovery Email' : this.creatingAccount ? this.guest ? 'Continue' : 'Create' : 'Login' },
        action() { return this.resettingPassword ? this.resetPassword : this.forgotPassword ? this.sendRecoveryEmail : this.creatingAccount ? this.createAccount : this.login },
        resettingPassword() { return !!this.$route.query?.salt },
        loggedIn() { return !!this.store.user?.id }
    }
}
</script>


<style lang="postcss" scoped>
input {
    @apply border rounded outline-blue-400 px-3 py-2 w-full dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-600
}
</style>
