import { reactive } from 'vue'

const screens = {
    '2xs': 425,
    xs: 475,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280
}

const breakpoints = reactive({ xs: false, sm: false, md: false, lg: false, xl: false })

const setBreakpoint = () => {
    breakpoints['2xs'] = window.innerWidth >= screens['2xs']
    breakpoints.xs = window.innerWidth >= screens.xs
    breakpoints.sm = window.innerWidth >= screens.sm
    breakpoints.md = window.innerWidth >= screens.md
    breakpoints.lg = window.innerWidth >= screens.lg
    breakpoints.xl = window.innerWidth >= screens.xl
}

const useBreakpoint = () => {

    setBreakpoint()
    window.addEventListener('resize', setBreakpoint)

    return {
        breakpoints
    }
}

export default useBreakpoint
export { screens }
